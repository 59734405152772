import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PreviousRevisionState } from "../../../../../apis/documentActionsApi";
import { isDraft } from "../../../../../app/helpers/documentsHelper";
import { useIsNewRevisionAvailable } from "../../../../../app/hooks/document/useIsNewRevisionAvailable";
import usePreviousNonDeletedRevision from "../../../../../app/hooks/document/usePreviousNonDeletedRevision";
import useSetStateDocumentAction from "../../../../../app/hooks/document/useSetStateDocumentAction";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import { useUserApplicationRoles } from "../../../../../app/hooks/permissions/roles/useUserApplicationRoles";
import {
  setNewDocumentVersionIdentity,
  setShouldRefreshDocumentMetadata,
} from "../../../../../app/slices/documentDetailsSlice";
import {
  decreaseChangingWorkflowCounter,
  increaseChangingWorkflowCounter,
  setIsDocumentsListFetching,
} from "../../../../../app/slices/documentListSlice";
import { StyledIconButton } from "../../../../../controls/StyledComponents/StyledIconButton";
import { TooltipMsg } from "../../../../../controls/Tooltips/TooltipMessages";
import DocumentListItem from "../../../../../models/documentList/documentListItem";
import { Workflow } from "../../../../../models/documentList/types/workflow";
import { WorkflowChangeType } from "../../../../../models/workflowChangeType";
import { DeletePopup } from "../../../../details/deletePopup/DeletePopup";
import { routing } from "../../../../pageLayout/PageLayout";
import { DocumentUrlPopup } from "../../../../popup/DocumentUrlPopup";
import GroupedDocumentsOptions from "./GroupedDocumentsOptions";
import SingleDocumentOptions from "./SingleDocumentOptions";
import { DocumentOptionsDiv } from "./styledComponents/DocumentOptionsDiv";

export interface DocumentOptionsProps {
  workflow: Workflow;
  isGrouped: boolean;
  url: string;
  documentItem?: DocumentListItem;
  onClick?: () => void;
  onWorkflowChange?: (workflowChangeType: WorkflowChangeType) => void;
}

export default function DocumentOptions(props: DocumentOptionsProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChangingWorkflow, setIsChangingWorkflow] = useState(false);

  const open = Boolean(anchorEl);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [previousRevisionState, setPreviousRevisionsState] = useState<
    PreviousRevisionState | undefined
  >();
  const [selectedWorkflowType, setSelectedWorkflowType] = useState<
    WorkflowChangeType | undefined
  >();
  const onWorkflowChange = props.onWorkflowChange;
  const { isSuperAdmin, canPublish } = useUserApplicationRoles();

  const {
    checkUserCanCreateNewRevision,
    isNewRevisionAvailable,
    getNewRevisionButtonDisableTooltip,
  } = useIsNewRevisionAvailable({
    hasPendingApprovals: props.documentItem?.hasPendingApprovals ?? false,
  });

  const onWorkflowChangeCompleted = useCallback(() => {
    if (selectedWorkflowType !== undefined && onWorkflowChange) {
      onWorkflowChange(selectedWorkflowType);
    }
  }, [selectedWorkflowType, onWorkflowChange]);

  const onWorkflowChangeLoading = useCallback(() => {
    dispatch(setIsDocumentsListFetching(true));
    dispatch(increaseChangingWorkflowCounter());
  }, [dispatch]);

  const onWorkflowChangeFinish = useCallback(() => {
    dispatch(setIsDocumentsListFetching(false));
    setSelectedWorkflowType(undefined);
    setIsChangingWorkflow(false);
    dispatch(decreaseChangingWorkflowCounter());
  }, [dispatch]);

  const {
    changeDocumentWorkflow,
    getPublishTooltip,
    getArchiveTooltip,
    getDeleteTooltip,
  } = useSetStateDocumentAction({
    onWorkflowChangeLoading,
    onWorkflowChangeError: onWorkflowChangeFinish,
    onWorkflowChangeFinish,
    onWorkflowChangeCompleted,
  });

  const identity = useMemo(() => {
    if (props.documentItem) {
      return {
        documentNumber: props.documentItem.documentNumber,
        documentLanguageCodes: props.documentItem.languageCodes,
        documentPart: props.documentItem.part,
        revision: props.documentItem.revision,
      };
    }
  }, [props.documentItem]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (identity) {
      checkUserCanCreateNewRevision({
        identity: identity,
        workflow: Workflow.NewRevision,
      });
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handlePopupOpen = (event: React.MouseEvent) => {
    if (props.onClick) {
      props.onClick();
    }
    event.stopPropagation();
    setAnchorEl(null);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const setIdentity = () => {
    if (props.documentItem) {
      dispatch(
        setNewDocumentVersionIdentity({
          revision: props.documentItem.revision,
          documentLanguageCodes: props.documentItem.languageCodes,
          documentNumber: props.documentItem.documentNumber,
          documentPart: props.documentItem.part,
        })
      );
      dispatch(setShouldRefreshDocumentMetadata(true));
    }
  };

  const handleOnNewClick = (url: string) => {
    if (props.documentItem) {
      setIdentity();
      navigate(url);
    }
  };

  const onChangeWorkflowClick = useCallback(
    (workflow: WorkflowChangeType, event?: React.MouseEvent) => {
      if (identity) {
        setIsChangingWorkflow(true);
        setSelectedWorkflowType(workflow);
        changeDocumentWorkflow(workflow, identity);
      }

      if (event) {
        event.stopPropagation();
        setAnchorEl(null);
      }
    },
    [identity, changeDocumentWorkflow]
  );

  const onFetchPreviousNonDeletedRevisionSuccess = useCallback(
    (revisionState: PreviousRevisionState) => {
      if (revisionState.canPreviousRevisionBePublished) {
        setIsDeletePopupOpen(true);
        setPreviousRevisionsState(revisionState);
      } else {
        onChangeWorkflowClick(WorkflowChangeType.Delete);
      }
    },
    [onChangeWorkflowClick]
  );

  const { fetchPreviousNonDeletedRevision } = usePreviousNonDeletedRevision({
    identity: identity,
    onSuccess: onFetchPreviousNonDeletedRevisionSuccess,
  });

  const onChangeWorkflowToDeleteClick = useCallback(
    (event: React.MouseEvent) => {
      if (props.documentItem?.isLatest) {
        event.stopPropagation();
        setAnchorEl(null);
        fetchPreviousNonDeletedRevision();
      } else {
        onChangeWorkflowClick(WorkflowChangeType.Delete, event);
      }
    },
    [
      props.documentItem?.isLatest,
      fetchPreviousNonDeletedRevision,
      onChangeWorkflowClick,
    ]
  );

  const getIsSetArchivedDisabled = () => {
    if (!identity || isChangingWorkflow) {
      return true;
    }

    if (isSuperAdmin) {
      return false;
    }

    return (
      !props.documentItem?.hasAccess ||
      (props.workflow === Workflow.Deleted && !props.documentItem.canDelete)
    );
  };

  const getIsSetPublishedDisabled = () => {
    if (
      !identity ||
      isChangingWorkflow ||
      (props.workflow === Workflow.Archived && !props.documentItem?.isLatest)
    ) {
      return true;
    }

    if (isSuperAdmin) {
      return false;
    }

    return (
      !props.documentItem?.hasAccess ||
      (props.workflow === Workflow.Deleted &&
        (!props.documentItem.canChangeToPublish ||
          !props.documentItem.canDelete))
    );
  };

  return (
    <div>
      <DocumentUrlPopup
        open={isPopupOpen}
        onClose={handlePopupClose}
        onClick={props.onClick}
        isDraft={isDraft(props.workflow) && !props.isGrouped}
        url={props.url}
      />
      <DeletePopup
        isOpen={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
        previousNonDeletedRevision={previousRevisionState}
        onKeepArchived={() => {
          setIsDeletePopupOpen(false);
          onChangeWorkflowClick(WorkflowChangeType.Delete);
        }}
        onPublishPreviousRevision={() => {
          setIsDeletePopupOpen(false);
          onChangeWorkflowClick(WorkflowChangeType.DeleteWithPublish);
        }}
        onDeleteAllRevisions={() => {
          setIsDeletePopupOpen(false);
          onChangeWorkflowClick(WorkflowChangeType.DeleteAll);
        }}
      />
      <DocumentOptionsDiv className="document-options-div">
        <StyledIconButton
          padding={0}
          onClick={handleClick}
          className="document-options-button"
        >
          <MoreVertIcon />
        </StyledIconButton>
        {props.isGrouped && (
          <GroupedDocumentsOptions
            isMenuOpened={open}
            menuAnchorEl={anchorEl}
            onMenuClose={handleClose}
            workflow={props.workflow}
            onShareUrlClick={handlePopupOpen}
            onNewLanguageClick={() => handleOnNewClick(routing.newLanguage)}
          />
        )}
        {!props.isGrouped && props.documentItem && (
          <SingleDocumentOptions
            isMenuOpened={open}
            menuAnchorEl={anchorEl}
            onMenuClose={handleClose}
            workflow={props.workflow}
            onShareUrlClick={handlePopupOpen}
            documentItem={props.documentItem}
            onNewFromTemplateClick={() => handleOnNewClick(routing.newTemplate)}
            onNewPartVersionClick={() => handleOnNewClick(routing.newPart)}
            onNewRevisionClick={() => handleOnNewClick(routing.newRevision)}
            onNewLanguageClick={() => handleOnNewClick(routing.newLanguage)}
            onSetPublishedClick={(event: React.MouseEvent) =>
              onChangeWorkflowClick(WorkflowChangeType.Publish, event)
            }
            onSetDeletedClick={(event: React.MouseEvent) => {
              onChangeWorkflowToDeleteClick(event);
            }}
            onSetArchived={(event: React.MouseEvent) =>
              onChangeWorkflowClick(WorkflowChangeType.Archive, event)
            }
            revisionButtonTooltipMessage={getNewRevisionButtonDisableTooltip()}
            disableRevisionButton={
              !isNewRevisionAvailable ||
              !canPublish ||
              (props.documentItem.hasPendingApprovals &&
                !props.documentItem.canApprove)
            }
            disableSetArchived={getIsSetArchivedDisabled()}
            disableSetDeleted={
              !identity ||
              isChangingWorkflow ||
              !(
                isSuperAdmin ||
                (props.documentItem.canDelete && props.documentItem.hasAccess)
              )
            }
            disableSetPublished={getIsSetPublishedDisabled()}
            disableSetArchivedTooltip={getArchiveTooltip(
              props.documentItem.hasAccess,
              props.documentItem.canDelete,
              props.workflow
            )}
            disableSetPublishedTooltip={getPublishTooltip(
              props.documentItem.hasAccess,
              props.workflow,
              props.documentItem.isLatest,
              props.documentItem.canChangeToPublish,
              props.documentItem.canDelete
            )}
            disableSetDeletedTooltip={getDeleteTooltip(
              props.documentItem.canDelete,
              props.documentItem.hasAccess
            )}
            disabledNewVersion={!canPublish}
            disabledNewVersionTooltip={
              !canPublish ? TooltipMsg.CantPublish : undefined
            }
          />
        )}
      </DocumentOptionsDiv>
    </div>
  );
}
